import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown/with-html";
import axios from "axios";
import Disqus from "disqus-react";
import renderHTML from "react-render-html";

import Layout from "../components/Layout";
import api from "../data/api";

function BlogDetails(props) {
    const [content, setContent] = useState("");
    const blogId = props.match.params.id;
    const blogFile = props.match.params.title;

    useEffect(() => {
        api("blog&id=" + blogId)
            .then(result => {
                setContent(result.data);
            })
            .catch(err => console.log(err));
    }, []);

    /* const disqusShortname = "chester-react"; //found in your Disqus.com dashboard
    const disqusConfig = {
        url: "https://tf-react-chester.now.sh/", //Homepage link of this site.
        identifier: blogId,
        title: blogFile
    }; */

    console.log(content.content);

    return (
        <Layout>
            <div className="mi-blog-details mi-section mi-padding-top mi-padding-bottom">
                <div className="container">
                    <div>
                        <img src={content.image} className="img-responsive" />
                    </div>
                    <h1>{content.title}</h1>
                    {content.content ? renderHTML(content.content) : null}
                    {/* <div className="mi-blog-details-comments mt-30">
                        <Disqus.DiscussionEmbed
                            shortname={disqusShortname}
                            config={disqusConfig}
                        />
                    </div> */}
                </div>
            </div>
        </Layout>
    );
}

export default BlogDetails;
