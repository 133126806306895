import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import api from "../data/api";
import LineIcon from "react-lineicons";

function Header() {
    const [information, setInformation] = useState("");
    const [navigationToggler, setNavigationToggler] = useState(false);

    const handleNavigationToggler = () => {
        setNavigationToggler(!navigationToggler);
    };

    useEffect(() => {
        api("information").then(response => {
            setInformation(response.data);
        });
    }, []);

    return (
        <nav
            className={navigationToggler ? "mi-header is-visible" : "mi-header"}
        >
            <button
                onClick={handleNavigationToggler}
                className="mi-header-toggler"
            >
                {!navigationToggler ? (
                    <LineIcon name="menu" />
                ) : (
                    <LineIcon name="close" />
                )}
            </button>
            <div className="mi-header-inner">
                <div className="mi-header-image">
                    <Link to="/">
                        {information.brandImage ? (
                            <img
                                src={information.brandImage}
                                alt="brandimage"
                            />
                        ) : null}
                    </Link>
                </div>

                <ul className="mi-header-menu">
                    <li>
                        <NavLink exact to="/">
                            <span>Главная</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/about">
                            <span>Обо мне</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/resume">
                            <span>Резюме</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/portfolios">
                            <span>Портфолио</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/blogs">
                            <span>Блог</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact">
                            <span>Контакты</span>
                        </NavLink>
                    </li>
                </ul>
                <p className="mi-header-copyright">
                    &copy; {new Date().getFullYear()}{" "}
                    <b>
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://uniqtripsoft.ru"
                        >
                            UniqTripSoft
                        </a>
                    </b>
                </p>
            </div>
        </nav>
    );
}

export default Header;
