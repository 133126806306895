import React, { useState, useEffect } from "react";
import api from "../data/api";
import axios from "axios";
import Slider from "react-slick";
import * as Icon from "react-feather";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import Service from "../components/Service";
import Testimonial from "../components/Testimonial";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

function About() {
    let [toggler, setToggler] = useState(false);
    const [information, setInformation] = useState("");
    const [services, setServices] = useState([]);
    const [reviews, setReviews] = useState([]);

    const sliderSettings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 6000,
        pauseOnHover: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {
        api("information").then(response => {
            setInformation(response.data);
        });
        api("services").then(response => {
            setServices(response.data);
        });

        api("reviews").then(response => {
            setReviews(response.data);
        });
    }, []);

    const name =
        information.name && information.name.split("\\").length > 1
            ? information.name.split("\\")[1]
            : information.name;
    if (!information) return null;
    return (
        <Layout>
            <div className="mi-about-area mi-section mi-padding-top">
                <div className="container">
                    <Sectiontitle title="Обо мне" />
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="mi-about-image">
                                <img
                                    src={information.aboutImage}
                                    alt="about"
                                    onClick={() => setToggler(true)}
                                />
                                <span className="mi-about-image-icon">
                                    <Icon.ZoomIn />
                                </span>

                                {toggler ? (
                                    <Lightbox
                                        mainSrc={information.aboutImageLg}
                                        onCloseRequest={() => setToggler(false)}
                                    />
                                ) : null}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mi-about-content">
                                <h3>
                                    Я{" "}
                                    <span className="color-theme">{name}</span>
                                </h3>
                                <p>{information.About}</p>
                                <ul>
                                    {!name ? null : (
                                        <li>
                                            <b>Имя</b> {name}
                                        </li>
                                    )}
                                    {!information.age ? null : (
                                        <li>
                                            <b>Возраст</b> {information.age} лет
                                        </li>
                                    )}
                                    {!information.phone ? null : (
                                        <li>
                                            <b>Телефон</b> {information.phone}
                                        </li>
                                    )}
                                    {!information.nationality ? null : (
                                        <li>
                                            <b>Национальность</b>{" "}
                                            {information.nationality}
                                        </li>
                                    )}
                                    {!information.language ? null : (
                                        <li>
                                            <b>Владение языками</b>{" "}
                                            {information.language}
                                        </li>
                                    )}
                                    {!information.email ? null : (
                                        <li>
                                            <b>Email</b> {information.email}
                                        </li>
                                    )}
                                    {!information.address ? null : (
                                        <li>
                                            <b>Адрес</b> {information.address}
                                        </li>
                                    )}
                                    {!information.freelanceStatus ? null : (
                                        <li>
                                            <b>Статус занятости</b>{" "}
                                            {information.freelanceStatus}
                                        </li>
                                    )}
                                </ul>
                                <a
                                    href={information.cvfile}
                                    className="mi-button"
                                >
                                    Скачать резюме
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mi-service-area mi-section mi-padding-top">
                <div className="container">
                    <Sectiontitle title="Услуги" />
                    <div className="mi-service-wrapper">
                        <div className="row mt-30-reverse">
                            {services.map(service => (
                                <div
                                    className="col-lg-4 col-md-6 col-12 mt-30"
                                    key={service.title}
                                >
                                    <Service content={service} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mi-review-area mi-section mi-padding-top mi-padding-bottom">
                <div className="container">
                    <Sectiontitle title="Отзывы" />
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <Slider
                                className="mi-testimonial-slider"
                                {...sliderSettings}
                            >
                                {reviews.map(review => (
                                    <Testimonial
                                        key={review.id}
                                        content={review}
                                    />
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default About;
