import React, { useState, useEffect } from "react";
import axios from "axios";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import BlogsView from "../components/BlogsView";
import Pagination from "../components/Pagination";
import api from "../data/api";

function Blogs() {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(6);

    useEffect(() => {
        api("blogs").then(response => {
            setPosts(response.data);
        });
    }, [posts]);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
    };

    return (
        <Layout>
            <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
                <div className="container">
                    <Sectiontitle title="Мои записи" />
                    <BlogsView blogs={currentPosts} />
                    {!(posts.length > postsPerPage) ? null : (
                        <Pagination
                            className="mt-50"
                            itemsPerPage={postsPerPage}
                            totalItems={posts.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Blogs;
