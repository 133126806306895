import React, { useState, useEffect } from "react";
import axios from "axios";
import { SocialIcon } from "react-social-icons";

function Socialicons(props) {
    const { socialLinks } = { ...props };
    if (!socialLinks) return null;
    console.log(socialLinks);
    return (
        <ul
            className={
                props.bordered
                    ? "mi-socialicons mi-socialicons-bordered"
                    : "mi-socialicons"
            }
        >
            {!socialLinks.facebook ? null : (
                <li>
                    <SocialIcon
                        target="_blank"
                        network="facebook"
                        url={socialLinks.facebook}
                        bgColor="rgba(0,0,0,0)"
                        fgColor="#FFF"
                    />
                </li>
            )}
            {!socialLinks.twitter ? null : (
                <li>
                    <SocialIcon
                        target="_blank"
                        network="twitter"
                        url={socialLinks.twitter}
                        bgColor="rgba(0,0,0,0)"
                        fgColor="#FFF"
                    />
                </li>
            )}
            {!socialLinks.vk ? null : (
                <li>
                    <SocialIcon
                        target="_blank"
                        network="vk"
                        url={socialLinks.vk}
                        bgColor="rgba(0,0,0,0)"
                        fgColor="#FFF"
                    />
                </li>
            )}
            {!socialLinks.instagram ? null : (
                <li>
                    <SocialIcon
                        target="_blank"
                        network="instagram"
                        url={socialLinks.instagram}
                        bgColor="rgba(0,0,0,0)"
                        fgColor="#FFF"
                    />
                </li>
            )}
            {!socialLinks.youtube ? null : (
                <li>
                    <SocialIcon
                        target="_blank"
                        network="youtube"
                        url={socialLinks.youtube}
                        bgColor="rgba(0,0,0,0)"
                        fgColor="#FFF"
                    />
                </li>
            )}
        </ul>
    );
}

export default Socialicons;
